

import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import { DomainsGroupsPermission } from '@/apiv2/domainsGroupPermissions';
import { defineComponent } from 'vue';
import ExternalDomainGroupAutomap from '@/apiv2/externalDomainGroupsAutomap';
import { Group } from '@/modules/businessProfile/businessProfileIdPIntegration/groupTypes';
import DeleteExternalDomainGroup from '@/apiv2/deleteExternalDomainGroup';

type Mode = 'add' | 'update';

export default defineComponent({
  name: 'add-user-group-idp',

  data () {
    return {
      loading: false,
      groupId: null,
      groupDisplayName: null,
      groupUse: null,
      permissionOptions: [],
      apiPermissions: [],
      existingGroups: null as Group[] | null,
      mode: 'add' as Mode,
      oldId: null,
    };
  },
  validations () {
    return {
      groupId: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
      groupDisplayName: {
        required,
      },
    };
  },
  methods: {
    show (item,
      permissionOption: { text: string; value: string },
      apiPermissions: DomainsGroupsPermission[],
      existingGroups: Group[]
    ) {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.show();
      this.groupId = item.groupId;
      this.groupDisplayName = item.displayName;
      this.groupUse = item.groupUse;
      if (this.groupId && this.groupDisplayName) {
        this.mode = 'update';
        this.oldId = item.groupId;
      }
      this.permissionOptions = [permissionOption];
      this.apiPermissions = apiPermissions;
      this.existingGroups = existingGroups;
    },

    async add () {
      try {
        this.$v.$touch();
        /**
         * there could be no changes so groupId stays the same,
         * we have only 2 values in the table, that is why groupId can be the same only once
         */
        if (this.existingGroups.every((group) => group.groupId === this.groupId)) {
          this.$toast.show({
            text: this.$t(
              'The UUID {uuid} is already assigned to the item {name}. Drop the existing item first to re-assign this UUID to a new item.',
              { uuid: this.groupId, name: this.groupDisplayName }
            ),
            color: 'yellow',
          });
          return;
        }
        if (this.mode === 'update') {
          await this.$api.authorizedCall(new DeleteExternalDomainGroup({ external_id: this.oldId }));
        }
        this.loading = true;
        await this.$api.authorizedCall(new ExternalDomainGroupAutomap({
          name: this.groupDisplayName,
          external_id: this.groupId,
          permission_uuid: this.apiPermissions.find((permission) => permission.display_name === this.groupUse).uuid,
        }));
      } finally {
        this.loading = false;
        this.$emit('refresh');
        this.hide();
      }
    },

    hide () {
      this.$v.$reset();
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.hide();
    },
  },
});

