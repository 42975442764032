/**
 * File: getExternalDomainGroupsRequest.ts
 *
 * Copyright:
 * Copyright © 2024 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';
import { ACCOUNT_SERVICE } from '@core/constants/services';

export interface ExternalUserGroup {
  'domain_uuid': string;
  'associated_groups': string[];
  'external_id': string;
  name: string;
  'parent_id': string;
}

interface GetExternalDomainGroupsResponseData {
  results: ExternalUserGroup[];
}

interface GetExternalDomainGroupsRequestParams {
  groupId?: string;
}

export default class GetExternalDomainGroupsRequest extends ApiRequest<GetExternalDomainGroupsRequestParams, GetExternalDomainGroupsResponseData> {
  get url (): string {
    const queryString = this.params.groupId ? this.buildQueryString({ id: this.params.groupId }) : '';
    return `/${ACCOUNT_SERVICE}/api/v2/external_auths/external_domain_groups${queryString}`;
  }

  getGroups (): ExternalUserGroup[] {
    return this.data.results;
  }
}
