/**
 * File: utils.ts
 *
 * Copyright © 2024 Parallels International GmbH. All rights reserved.
 *
 */
import {
  GET_SUPPORT_PAGE,
  PDE_COMPUTERS_PAGE,
  PDE_CONFIGURATION_PROFILES_PAGE,
  PDE_SUBPAGE_SUBSCRIPTIONS
} from '@/routes/constants';
import { STATUS_ACTIVE } from '@/models/subscription';
import { SUBNAVIGATION_SOURCE } from '@/modules/subscriptions/constants';
import { MODAL_INVITE } from '@/modules/productPageMixIn';
import { PRODUCT_KEY_NAME_PDE } from '@core/constants/subscriptions';
import { FEATURE_SUPPORT_PORTAL } from '@core/constants/features';
import Vue from 'vue';

export function getPdeSubpages (component: Vue & { subscriptionsAmount: number; configurationProfilesAmount: number; supportLinks: Record<string, string>; computersAmount: number }): MenuItem[] {
  const links = [];
  // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16431
  const downloadLink = component.getDownloadRoute(PRODUCT_KEY_NAME_PDE);
  if (component.$appData.session.isBusinessAdmin) {
    links.push({
      text: component.$t('Management Portal'),
      click: () => {
        window.open(component.$appData.session.pdaPortalUrl, '_blank');
      },
    });
  }
  links.push(
    {
      text: `${component.$t('Registered Computers')} (${component.computersAmount})`,
      link: { name: PDE_COMPUTERS_PAGE },
    },
    {
      text: component.$t('Download'),
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16431
      link: downloadLink,
    },
    {
      text: component.$t('Product Subscriptions ({amount})', { amount: component.subscriptionsAmount }),
      link: { name: PDE_SUBPAGE_SUBSCRIPTIONS, query: { display: STATUS_ACTIVE, source: SUBNAVIGATION_SOURCE } },
    },
    {
      text: component.$t('Invite Parallels Desktop Users'),
      click: () => {
        // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16280
        component.handleModal(MODAL_INVITE);
      },
    },
    {
      text: `${component.$t('Configuration Profiles ({amount})', { amount: component.configurationProfilesAmount })}`,
      link: { name: PDE_CONFIGURATION_PROFILES_PAGE, params: { product: PRODUCT_KEY_NAME_PDE } },
    },
    {
      text: component.$t('Support'),
      click: () => {
        if (component.$appData.session.isFeatureAccessible(FEATURE_SUPPORT_PORTAL)) {
          const params = {
            category: 'Support',
            name: 'Support button click in menu',
          };
          component.$collectGaStats({ ...params, source: document.title });
          component.$collectStats({ ...params, source: PRODUCT_KEY_NAME_PDE });
          component.$router.push({ name: GET_SUPPORT_PAGE, params: { product: PRODUCT_KEY_NAME_PDE } });
        } else {
          window.location.href = component.supportLinks[PRODUCT_KEY_NAME_PDE];
        }
      },
    }
  );
  return links;
}
