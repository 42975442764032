/**
 * File: createGroupLicenseRequest.ts
 *
 * Copyright:
 * Copyright © 2024 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest, { Method } from '@core/api/apiRequest';
import { LICENSE_SERVICE } from '@core/constants/services';
import { UserGroup } from '@/api/getGroupLicenseRequest';

interface CreateGroupLicenseRequestParams {
  groupUuid: string;
  licenseUuid: string;
}

export default class CreateGroupLicenseRequest extends ApiRequest<CreateGroupLicenseRequestParams, UserGroup> {
  get url (): string {
    return `/${LICENSE_SERVICE}/api/v2/domains/group_license`;
  }

  get method (): Method {
    return 'POST';
  }

  getGroup (): UserGroup {
    return this.data;
  }

  get body () {
    return {
      group_uuid: this.params.groupUuid,
      license_uuid: this.params.licenseUuid,
    };
  }

  get cacheNameSpace (): string | undefined {
    return undefined;
  }
}
