
import Locale from '@/plugins/locale';
import StepAbstract from './stepAbstract.vue';
import EditUserGroupIdp from './editUserGroupIdp.vue';
import DomainsGroupPermissions, { DomainsGroupsPermission } from '@/apiv2/domainsGroupPermissions';
import InternalGroups from '@/apiv2/internalGroups';
import ExternalGroups from '@/apiv2/externalGroups';
import DeleteExternalDomainGroup from '@/apiv2/deleteExternalDomainGroup';
import { Group } from '@/modules/businessProfile/businessProfileIdPIntegration/groupTypes';

const PRESET_FIRST_GROUP: Group = {
  groupId: undefined,
  displayName: undefined,
  groupUse: 'Parallels Business Account Admins',
};

const PRESET_SECOND_GROUP: Group = {
  groupId: undefined,
  displayName: undefined,
  groupUse: 'Parallels Desktop Users',
};

export default StepAbstract.extend({
  name: 'step-configure-user-groups-mapping',
  components: {
    EditUserGroupIdp,
  },
  props: {
    title: {
      default: () => { return Locale.i18n.t('Configure User Groups Mapping'); },
    },
    mandatory: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      loading: false,
      permissions: null as DomainsGroupsPermission[] | null,
      firstGroup: PRESET_FIRST_GROUP,
      secondGroup: PRESET_SECOND_GROUP,
      selectedGroupIds: [],
      columns: {
        options: [
          { text: this.$t('Group ID'), value: 'groupId' },
          { text: this.$t('Group Display Name'), value: 'displayName' },
          { text: this.$t('Group Use'), value: 'groupUse' },
        ],
      },
    };
  },
  async created () {
    this.loading = true;
    try {
      const data = await this.$api.authorizedCall(new DomainsGroupPermissions());
      this.permissions = data.results;
      await this.getUserGroups();
    } finally {
      this.loading = false;
    }
  },
  methods: {
    callAction ({ action, items }: ActionCallParameters): void {
      (this[action] as CallbackFunctionType)(items);
    },
    editUserGroup (item: Group) {
      // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.editUserGroupIdp.show(item, { text: item.groupUse, value: item.groupUse }, this.permissions, this.groups);
    },
    async groupRemove () {
      try {
        this.loading = true;
        for (const id of this.selectedGroupIds) {
          const group = this.groups.find((group) => group.groupId === id);
          if (group?.groupId) {
            await this.$api.authorizedCall(new DeleteExternalDomainGroup({ external_id: group.groupId }));
            if (group.groupUse === 'Parallels Business Account Admins') {
              this.$toast.show({
                text: this.$t('Please add the group for business account admins!'),
                color: 'yellow',
              });
            }
          }
        }
      } finally {
        this.loading = false;
        await this.getUserGroups();
      }
    },
    async getUserGroups () {
      this.loading = true;
      try {
        this.firstGroup = PRESET_FIRST_GROUP;
        this.secondGroup = PRESET_SECOND_GROUP;
        const [internalGroups, externalGroups] = await Promise.all([
          this.$api.authorizedCall(new InternalGroups()),
          this.$api.authorizedCall(new ExternalGroups()),
        ]);
        const internalGroupsMap = internalGroups.results?.reduce((acc, group) => {
          acc[group.uuid] = group;
          return acc;
        }, {});
        // eslint-disable-next-line no-unused-expressions
        externalGroups.results?.forEach((group) => {
          // there always be only one associated group by design.
          const permission = internalGroupsMap[group.associated_groups[0]]?.permissions[0];
          // console.log(internalGroupsMap[group.associated_groups[0]], permission, group);
          if (internalGroupsMap[group.associated_groups[0]] && permission?.['display_name'] === 'Parallels Business Account Admins') {
            this.firstGroup = {
              groupId: group.external_id,
              displayName: group.name,
              groupUse: permission.display_name, // we suppose that for one groupID can be only one groupUse
              permissions: [permission],
            };
          }
          if (internalGroupsMap[group.associated_groups[0]] && permission?.['display_name'] === 'Parallels Desktop Users') {
            this.secondGroup = {
              groupId: group.external_id,
              displayName: group.name,
              groupUse: permission.display_name, // we suppose that for one groupID can be only one groupUse
              permissions: [permission],
            };
          }
        });
      } finally {
        this.loading = false;
      }
    },
    async updateOnAddOrEdit () {
      await this.getUserGroups();
      // Parallels Business Account Admins is minimum group that must be set
      if (this.firstGroup.groupId) {
        this.emitDone(true);
      }
    },
  },
  computed: {
    groups (): Group[] {
      return [
        this.firstGroup,
        this.secondGroup,
      ];
    },
    actions (): TableViewActions {
      // If no groups - no actions, no selection
      if (this.groups.every((group) => !group.groupId)) {
        return;
      }
      const actions = [];
      if (this.selectedGroupIds.length) {
        actions.push({
          value: 'groupRemove',
          text: this.$t('Remove ({count})', { count: this.selectedGroupIds.length }),
        });
      }

      return {
        id: 'groupId',
        options: actions,
      };
    },
  },
});
