import ApiRequest from '@core/api/apiRequest';
import Service from '@core/constants/services';

interface ExternalGroupsParams {
  limit?: number;
  offset?: number;
}

interface ExternalGroupsResponse {
  count: number;
  next: string;
  previous: string;
  results: {
    'domain_uuid': string;
    'associated_groups': string[];
    'external_id': string;
    name: string;
    'parent_id': string;
    'created_at': string;
    'updated_at': string;
  }[];
}

export default class ExternalGroups extends ApiRequest<ExternalGroupsParams, ExternalGroupsResponse> {
  get method () {
    return 'GET' as const;
  }

  get url () {
    return `/${Service.Account}/api/v2/external_auths/external_domain_groups`;
  }

  get cacheNameSpace (): string | undefined {
    return undefined;
  }
}
