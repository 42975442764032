/**
 * File: deleteGroupLicenseRequest.ts
 *
 * Copyright:
 * Copyright © 2024 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest, { Method } from '@core/api/apiRequest';
import { LICENSE_SERVICE } from '@core/constants/services';

interface DeleteGroupLicenseRequestParams {
  uuid: string;
}

export default class DeleteGroupLicenseRequest extends ApiRequest<DeleteGroupLicenseRequestParams, void> {
  get url (): string {
    return `/${LICENSE_SERVICE}/api/v2/domains/group_license/${this.params.uuid}`;
  }

  get method (): Method {
    return 'DELETE';
  }

  get cacheNameSpace (): string | undefined {
    return undefined;
  }
}
