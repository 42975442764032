import ApiRequest from '@core/api/apiRequest';
import Service from '@core/constants/services';
import DomainsGroupPermission from '@/apiv2/externalGroups';

interface InternalGroupsParams {
  limit?: number;
  offset?: number;
}

interface InternalGroupsResponse {
  count: number;
  next: string;
  previous: string;
  results: {
    uuid: string;
    name: string;
    'parent_uuid': string;
    permissions: DomainsGroupPermission[];
    domain: {
      uuid: string;
      'external_id': number;
      confirmed: boolean;
    };
  }[];
}

export default class InternalGroups extends ApiRequest<InternalGroupsParams, InternalGroupsResponse> {
  get method () {
    return 'GET' as const;
  }

  get url () {
    return `/${Service.Account}/api/v2/domains/domain_groups`;
  }

  get cacheNameSpace (): string | undefined {
    return undefined;
  }
}
