/**
 * File: createExternalDomainGroupsRequest.ts
 *
 * Copyright:
 * Copyright © 2024 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest, { Method } from '@core/api/apiRequest';
import { ACCOUNT_SERVICE } from '@core/constants/services';

interface CreateExternalDomainGroupsResponseData {
  name: string;
  'external_id': string;
  'associated_groups': string[];
}

interface CreateExternalDomainGroupsRequestParams {
  name: string;
  externalId: string;
  currentExternalDomain?: string;
  permissionUuid?: string;
}

export default class CreateExternalDomainGroupsRequest extends ApiRequest<CreateExternalDomainGroupsRequestParams, CreateExternalDomainGroupsResponseData> {
  get url (): string {
    return `/${ACCOUNT_SERVICE}/api/v2/external_auths/external_domain_groups/create_with_automap`;
  }

  get method (): Method {
    return 'POST';
  }

  getGroup (): CreateExternalDomainGroupsResponseData {
    return this.data;
  }

  get body () {
    return {
      name: this.params.name,
      external_id: this.params.externalId,
      permission_uuid: this.params.permissionUuid || null,
      current_external_domain: this.params.currentExternalDomain || '',
    };
  }

  get cacheNameSpace (): string | undefined {
    return undefined;
  }
}
