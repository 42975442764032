/**
 * File: getGroupLicenseRequest.ts
 *
 * Copyright:
 * Copyright © 2024 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';
import { LICENSE_SERVICE } from '@core/constants/services';

export interface UserGroup {
  uuid: string;
  'group_uuid': string;
  'license_uuid': string;
  'license_type': string;
  'created_at': string;
}

interface GetGroupLicenseResponseData {
  results: UserGroup[];
}

interface GetGroupLicenseRequestParams {
  uuid: string;
}

export default class GetGroupLicenseRequest extends ApiRequest<GetGroupLicenseRequestParams, GetGroupLicenseResponseData> {
  get url (): string {
    const queryString = this.buildQueryString({ license_uuid: this.params.uuid });
    return `/${LICENSE_SERVICE}/api/v2/domains/group_license${queryString}`;
  }

  getGroups (): UserGroup[] {
    return this.data.results;
  }
}
