

import { defineComponent, PropType } from 'vue';
import { companyName, required } from '@core/common/validators';
import Subscription from '@/models/subscription';
import GetGroupLicenseRequest, { UserGroup } from '@/api/getGroupLicenseRequest';
import CreateGroupLicenseRequest from '@/api/createGroupLicenseRequest';
import GetExternalDomainGroupsRequest, { ExternalUserGroup } from '@/api/getExternalDomainGroupsRequest';
import CreateExternalDomainGroupsRequest from '@/api/createExternalDomainGroupsRequest';
import DeleteGroupLicenseRequest from '@/api/deleteGroupLicenseRequest';
import DeleteExternalDomainGroup from '@/apiv2/deleteExternalDomainGroup';

export interface UserGroupFormItem {
  uuid: string;
  displayName: string;
  userGroup?: UserGroup;
  externalGroup?: ExternalUserGroup;
  disabled?: boolean;
}

export default defineComponent({
  name: 'user-groups-tab',
  props: {
    subscription: {
      type: Object as PropType<Subscription>,
      required: true,
    },
    subset: {
      type: Object,
    },
  },
  data (): any {
    return {
      groups: [],
      initialGroups: [],
      loading: false,
      uuid: null,
    };
  },
  validations: {
    groups: {
      $each: {
        displayName: companyName,
        uuid: { required },
      },
    },
  },
  created () {
    this.loadGroups().then(() => { this.$v.$reset(); });
    this.$bus.$on('subsetSaved', this.save);
  },

  destroyed () {
    this.$bus.$off('subsetSaved', this.save);
  },

  methods: {
    getUuid () {
      return this.subset.subsetUuid || this.subset.subscriptionUuid;
    },

    resetForm () {
      this.groups = [];
      this.initialGroups = [];
      this.$v.$reset();
    },

    handleAddGroup () {
      this.groups.push({ uuid: '', displayName: '' });
      this.emitIsInvalid();
      this.$v.$reset();
    },

    handleRemoveGroup (index: number) {
      this.groups.splice(index, 1);
      this.emitIsInvalid();
    },

    setGroups (userGroups: UserGroup[], externalGroups: ExternalUserGroup[]): void {
      const result: UserGroupFormItem[] = [];
      userGroups.forEach((group: UserGroup) => {
        const externalGroup = externalGroups.find((g: ExternalUserGroup) => g.associated_groups[0] === group.group_uuid);
        if (externalGroup) {
          result.push({
            userGroup: group,
            externalGroup,
            uuid: externalGroup.external_id,
            displayName: externalGroup.name,
            disabled: true,
          });
        }
      });
      this.groups = result;
      this.initialGroups = [...result];
    },

    async loadGroups () {
      if (!this.subset) {
        return;
      }
      this.resetForm();
      this.uuid = this.getUuid();
      const licenseGroupsRequest = new GetGroupLicenseRequest({ uuid: this.uuid });
      licenseGroupsRequest.dropCache();
      const externalGroupsRequest = new GetExternalDomainGroupsRequest();
      externalGroupsRequest.dropCache();
      this.loading = true;
      try {
        await this.$api.authorizedCall(licenseGroupsRequest);
        const userGroups = licenseGroupsRequest.getGroups();
        if (userGroups.length) {
          await this.$api.authorizedCall(externalGroupsRequest);
          this.setGroups(userGroups, externalGroupsRequest.getGroups());
        }
      } finally {
        this.loading = false;
      }
    },

    async createGroup (group: UserGroupFormItem): Promise<any> {
      let createExternalDomainGroupsRequest = null;
      try {
        createExternalDomainGroupsRequest = new CreateExternalDomainGroupsRequest({
          externalId: group.uuid,
          name: group.displayName,
        });
        await this.$api.authorizedCall(createExternalDomainGroupsRequest);
        const userGroup = createExternalDomainGroupsRequest.getGroup();
        const userGroupId = userGroup.associated_groups[0];
        const createUserGroupsRequest =
          new CreateGroupLicenseRequest({ licenseUuid: this.uuid, groupUuid: userGroupId });
        await this.$api.authorizedCall(createUserGroupsRequest);
      } catch (e) {
        const err = await createExternalDomainGroupsRequest?.error?.response.json();
        if (err?.['non_field_errors'][0] === 'External group already exists and associated with internal group') {
          this.$toast.show({
            text: this.$t(`Ambiguous link: the user group ${group.displayName} (${group.uuid}) is linked to a different sublicense!`),
            color: 'red',
          });
        } else {
          this.$toast.show({
            text: this.$t('Your request cannot be submitted at the moment. Please try again later.'),
            color: 'red',
          });
        }
      }
    },

    async removeGroup (group: UserGroupFormItem): Promise<any> {
      try {
        const groupUuid = group.userGroup && group.userGroup.uuid;
        const deleteGroupRequest = new DeleteGroupLicenseRequest({ uuid: groupUuid });
        await this.$api.authorizedCall(new DeleteExternalDomainGroup({ external_id: group.uuid }));
        await this.$api.authorizedCall(deleteGroupRequest);
      } catch (e) {
        this.$toast.show({
          text: this.$t('Your request cannot be submitted at the moment. Please try again later.'),
          color: 'red',
        });
      }
    },

    async save () {
      const { addedGroups, deletedGroups } = this.detectChanges();
      const deletedPromises = await Promise.all(deletedGroups.map(async (group: UserGroupFormItem) => await this.removeGroup(group)));
      const addedPromises = await Promise.all(addedGroups.map(async (group: UserGroupFormItem) => await this.createGroup(group)));
      const promises = [...addedPromises, ...deletedPromises];
      this.resetForm();
      this.$emit('save', promises);
    },

    handleGroupInputChange () {
      this.emitIsInvalid();
    },

    emitIsInvalid () {
      this.$emit('isGroupInvalid', (this as any).$v.$invalid);
    },

    detectChanges () {
      const addedGroups = this.groups.filter((group) => !group.externalGroup);
      const deletedGroups = this.initialGroups.filter((initGroup: UserGroupFormItem) => !this.groups.find((group) => group.uuid === initGroup.uuid && group.displayName === initGroup.displayName));
      const notDeletedGroups = this.initialGroups.filter((initGroup: UserGroupFormItem) => this.groups.find((group) => group.uuid === initGroup.uuid && group.displayName === initGroup.displayName));
      const duplicatesAddedGroups = addedGroups.filter((addedGroup) => notDeletedGroups.find((group) => group.uuid === addedGroup.uuid || group.displayName === addedGroup.displayName));
      if (duplicatesAddedGroups.length) {
        duplicatesAddedGroups.forEach((group) => {
          this.$toast.show({
            text: this.$t(`Duplicate link: the user group ${group.displayName} (${group.uuid}) is already linked to this sublicense!`),
            color: 'red',
          });
        });
      }
      const uniqueAddedGroups = addedGroups
        .filter((addedGroup) => !notDeletedGroups
          .find((group) => group.uuid === addedGroup.uuid || group.displayName === addedGroup.displayName)
        ).reduce((unique, current) => {
          if (!unique.find(group => group.uuid === current.uuid || group.displayName === current.displayName)) {
            unique.push(current);
          }
          return unique;
        }, []);
      return { addedGroups: uniqueAddedGroups, deletedGroups };
    },
  },
  watch: {
    subset () {
      this.loadGroups();
    },
  },
});

